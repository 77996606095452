<template>
  <div class="gp-box-fp">
    <full-page :options="options" id="box-fullpage">
        <div class="section" id="section-box-home" data-anchor="box">
          <HomeSlide/>
        </div>
        <div class="section" id="section-box-info" data-anchor="info">
          <AboutSlide/>
        </div>
        <div class="section" id="section-box-booking" data-anchor="booking">
          <BookingSlide></BookingSlide>
        </div>
        <div class="section" id="section-box-contact" data-anchor="contact">
          <ContactSlide></ContactSlide>
        </div>
    </full-page>
  </div>
</template>

<script>
// import {AboutSlide, InfoSlide, AdvantagesSlide} from '@/views/home-sections'
import HomeSlide from '@/views/box-sections/HomeSlide'
import AboutSlide from '@/views/box-sections/AboutSlide'
import BookingSlide from '@/views/box-sections/BookingSlide'
import ContactSlide from '@/views/box-sections/ContactSlide'

export default {
  name: 'Home',
  components: {
    HomeSlide, AboutSlide, BookingSlide, ContactSlide
  },
  data() {
    return {
      options: {
        licenseKey: '02AD849D-157D4E03-A7214D08-A242BB8A',
        // navigation: true,
        showActiveTooltip: true,
        anchors:['box'],
        sectionsColor:['transparent', 'transparent', 'transparent', '#ffffff', '#ffffff', '#ffffff', '#ffffff']
      },
    }
  },
  // beforeCreate: function() {
  //     document.body.className = 'fp-viewing-home';
  // }
  mounted() {
    document.body.classList.add('home-slider-page')
  },
  destroyed () {
    document.body.classList.remove('home-slider-page')
  }
}
</script>

<style lang="scss">
.gp-box-fp .fp-tableCell {
  padding-top: 0;
}
</style>
