<template>
  <div class="gp-box-booking-slide-content">
    <div class="gp-box-booking">
      <svg version="1.1" id="plan" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1920 1080" style="enable-background:new 0 0 1920 1080;" xml:space="preserve">
      <image style="overflow:visible;" width="1920" height="1080" id="background" xlink:href="/assets/images/box/plan-bg.png" ></image>
      <g id="layers">
        <path id="G01" @click="boxInfo" :class="pathClass('G01')" d="M1697.5,518.5h-72v-144l81,10l4,143.5h-11L1697.5,518.5z"/>
        <path id="G02" @click="boxInfo" :class="pathClass('G02')" d="M1706,375.5l-81-10V233l77.5,9L1706,375.5z"/>
        <path id="F01" @click="boxInfo" :class="pathClass('F01')" d="M1332,450.5l-4.5-43.5h79l5,43.5H1332z"/>
        <path id="F10" @click="boxInfo" :class="pathClass('F10')" d="M1419.5,450.5l-5-43h78.5l6.5,43H1419.5z"/>
        <path id="F09" @click="boxInfo" :class="pathClass('F09')" d="M1413.5,397l-5-42.5h77.5l5.5,42.5H1413.5z"/>
        <path id="F02" @click="boxInfo" :class="pathClass('F02')" d="M1326.5,397l-4-42.5h78l4.5,42.5H1326.5z"/>
        <path id="F03" @click="boxInfo" :class="pathClass('F03')" d="M1322.5,347l-4.5-41h77l5,41H1322.5z"/>
        <path id="F08" @click="boxInfo" :class="pathClass('F08')" d="M1407,347.5l-4.5-41.5h76.5l6,41.5H1407z"/>
        <path id="F07" @click="boxInfo" :class="pathClass('F07')" d="M1401,297l-4.5-39.5h75.5l5.5,39.5H1401z"/>
        <path id="F04" @click="boxInfo" :class="pathClass('F04')" d="M1316.5,297l-3.5-40h76l5,40H1316.5z"/>
        <path id="F05" @click="boxInfo" :class="pathClass('F05')" d="M1312,250l-3.5-39h75l5,39H1312z"/>
        <path id="F06" @click="boxInfo" :class="pathClass('F06')" d="M1396,250l-5-38.5h74.5l5.5,38.5H1396z"/>
        <path id="E01" @click="boxInfo" :class="pathClass('E01')" d="M1039,451v-44h80l1.5,44H1039z"/>
        <path id="E10" @click="boxInfo" :class="pathClass('E10')" d="M1127.5,451l-2-44.5h80l2.5,44.5H1127.5z"/>
        <path id="E09" @click="boxInfo" :class="pathClass('E09')" d="M1125,397l-1.5-43h78.5l3,43H1125z"/>
        <path id="E02" @click="boxInfo" :class="pathClass('E02')" d="M1038.5,397l-1-43h79l2.5,43H1038.5z"/>
        <path id="E03" @click="boxInfo" :class="pathClass('E03')" d="M1038,347l-1-41h77.5l2,41H1038z"/>
        <path id="E08" @click="boxInfo" :class="pathClass('E08')" d="M1123,347.5l-2-41.5h77.5l3.5,41.5H1123z"/>
        <path id="E07" @click="boxInfo" :class="pathClass('E07')" d="M1121,297l-1.5-40h76l3,40H1121z"/>
        <path id="E04" @click="boxInfo" :class="pathClass('E04')" d="M1037,296.5l-1-39.5h77l1,39.5H1037z"/>
        <path id="E05" @click="boxInfo" :class="pathClass('E05')" d="M1036,249.5l-1-39h76.5l1.5,39H1036z"/>
        <path id="E06" @click="boxInfo" :class="pathClass('E06')" d="M1119,250l-1.5-39.5h76l2.5,39.5H1119z"/>
        <path id="D01" @click="boxInfo" :class="pathClass('D01')" d="M748.5,449.5L752,405h81l-2,44.5H748.5z"/>
        <path id="D10" @click="boxInfo" :class="pathClass('D10')" d="M838,449.5l1.5-44.5H920l-1,44.5H838z"/>
        <path id="D09" @click="boxInfo" :class="pathClass('D09')" d="M839,396l2-43h79.5l-1,43H839z"/>
        <path id="D02" @click="boxInfo" :class="pathClass('D02')" d="M753,396l2.5-43H835l-1,43H753z"/>
        <path id="D03" @click="boxInfo" :class="pathClass('D03')" d="M755.5,346l3-41H837l-1.5,41H755.5z"/>
        <path id="D08" @click="boxInfo" :class="pathClass('D08')" d="M841,346.5l2-42h1h77v42H841z"/>
        <path id="D07" @click="boxInfo" :class="pathClass('D07')" d="M843,296l1.5-39.5H922l-1,39.5H843z"/>
        <path id="D04" @click="boxInfo" :class="pathClass('D04')" d="M758.5,296l3-40h77l-2,40H758.5z"/>
        <path id="D05" @click="boxInfo" :class="pathClass('D05')" d="M762,249l2.5-39H840l-1,39H762z"/>
        <path id="D06" @click="boxInfo" :class="pathClass('D06')" d="M845,249l1.5-39H923l-1.5,39H845z"/>
        <path id="C01" @click="boxInfo" :class="pathClass('C01')" d="M223,422l19.5-94.5H314L297.5,422H223z"/>
        <path id="C02" @click="boxInfo" :class="pathClass('C02')" d="M304.5,422.5l17.5-95h72l-14.5,95H304.5z"/>
        <path id="C03" @click="boxInfo" :class="pathClass('C03')" d="M386,423l15.5-95.5H474L462,423H386z"/>
        <path id="C04" @click="boxInfo" :class="pathClass('C04')" d="M470,423l12-95.5h72L544,423H470z"/>
        <path id="C05" @click="boxInfo" :class="pathClass('C05')" d="M550.5,423l5.5-43.5h78.5L631,423H550.5z"/>
        <path id="C06" @click="boxInfo" :class="pathClass('C06')" d="M557,370l4.5-42h1h77l-3.5,42H557z"/>
        <path id="C07" @click="boxInfo" :class="pathClass('C07')" d="M562.5,321l5-40.5H644l-3.5,40.5H562.5z"/>
        <path id="C08" @click="boxInfo" :class="pathClass('C08')" d="M567.5,271l4.5-38.5h76l-3.5,38.5H567.5z"/>
        <path id="C09" @click="boxInfo" :class="pathClass('C09')" d="M572.5,225l5-38H652l-3,38H572.5z"/>
        <path id="B07" @click="boxInfo" :class="pathClass('B07')" d="M1734,717.5h-100.5l-34.5-196h96.5L1734,717.5z"/>
        <path id="B12" @click="boxInfo" :class="pathClass('B12')" d="M1512,617h-86l-9-80h83.5L1512,617z"/>
        <path id="B05" @click="boxInfo" :class="pathClass('B05')" d="M1526,720h-88l-12-103h85.5L1526,720z"/>
        <path id="B11" @click="boxInfo" :class="pathClass('B11')" d="M1418,617h-85.5l-7-80h83L1418,617z"/>
        <path id="B04" @click="boxInfo" :class="pathClass('B04')" d="M1429.5,720H1341l-9-103h86L1429.5,720z"/>
        <path id="B10" @click="boxInfo" :class="pathClass('B10')" d="M1318,537h-84.5l5,79.5h86.5L1318,537z"/>
        <path id="B03" @click="boxInfo" :class="pathClass('B03')" d="M1324.5,617h-86l7,103h88.5L1324.5,617z"/>
        <path id="B01" @click="boxInfo" :class="pathClass('B01')" d="M1150.5,716.5H1041l-1-109.5h105.5L1150.5,716.5z"/>
        <path id="B08" @click="boxInfo" :class="pathClass('B08')" d="M1145.5,607.5H1040l-1-88.5h103.5L1145.5,607.5z"/>
        <path id="B09" @click="boxInfo" :class="pathClass('B09')" d="M1167.5,519h-25l3,88.5h27l1,9h57.5l-5-79.5h-57L1167.5,519z"/>
        <path id="B02" @click="boxInfo" :class="pathClass('B02')" d="M1145.5,607.5l5,109h26.5v4h61l-7-104h-57.5l-1-9H1145.5z"/>
        <path id="B06" @click="boxInfo" :class="pathClass('B06')" d="M1573,616.5h-53l14.5,104h57l1.5,5.5l3.5-8.5h37l-19.5-111h-35L1573,616.5z"/>
        <path id="B13" @click="boxInfo" :class="pathClass('B13')" d="M1573,616.5h-53l-11-79h52.5l2-4v-5l3.5-7h32l15,85h-35L1573,616.5z"/>
        <path id="A07" @click="boxInfo" :class="pathClass('A07')" d="M889.5,716.5H780l5-109.5h105.5L889.5,716.5z"/>
        <path id="A06" @click="boxInfo" :class="pathClass('A06')" d="M785,607l-5,109.5h-27l0.5,2H693l7-106.5h14.6H758l0.5-5H785z"/>
        <path id="A14" @click="boxInfo" :class="pathClass('A14')" d="M890.5,606.5H785l5-87.5h101.5L890.5,606.5z"/>
        <path id="A13" @click="boxInfo" :class="pathClass('A13')" d="M790,519l-5,88h-26.5l-0.5,5h-58l6-77h57l1-16H790z"/>
        <path id="A05" @click="boxInfo" :class="pathClass('A05')" d="M596.5,718L607,612h86l-7.5,106H596.5z"/>
        <path id="A12" @click="boxInfo" :class="pathClass('A12')" d="M693,612h-86l6.5-77H698L693,612z"/>
        <path id="A04" @click="boxInfo" :class="pathClass('A04')" d="M500.5,718L514,612h85.5l-10,106H500.5z"/>
        <path id="A11" @click="boxInfo" :class="pathClass('A11')" d="M599.5,612H514l8.5-76.5h84L599.5,612z"/>
        <path id="A03" @click="boxInfo" :class="pathClass('A03')" d="M405,717.5L420.5,612H506l-12.5,105.5H405z"/>
        <path id="A10" @click="boxInfo" :class="pathClass('A10')" d="M506,612h-85.5l10.5-76.5h84L506,612z"/>
        <path id="A02" @click="boxInfo" :class="pathClass('A02')" d="M329,717.5h68l15.5-106h-64l-8-4.5H314l-19,107.5h29.5l4.5,9V717.5z"/>
        <path id="A09" @click="boxInfo" :class="pathClass('A09')" d="M329.5,517.5L314,607h26.5l8,4.5h64L423,535h-63l-1-4v-4l-3.5-9.5H329.5z"/>
        <path id="A01" @click="boxInfo" :class="pathClass('A01')" d="M188,714.5h107L314,607H209.5L188,714.5z"/>
        <path id="A08" @click="boxInfo" :class="pathClass('A08')" d="M227,517.5L209.5,607H314l15.5-89.5H227z"/>
      </g>
      </svg>
    </div>
    <transition :name="transitionPosition">
      <div :class="bookingInfoClass" v-if="activeBox">
        <div class="gp-box-booking-container">
          <button type="button" class="btn-close" @click="closeBoxInfo()" aria-label="Close"></button>
          <h3 class="gp-box-booking-title">{{ title }}</h3>
          <div class="gp-box-booking-suptitle">{{ this.boxSizeTypes[box[activeBox].size].line }} лінія <span @click="showSameBoxes(sameSizeItems.elements)">Кількість схожих боксів: {{ sameSizeItems.count }} шт</span></div>
          <div class="gp-box-specifications">
            <span v-bind:class="'specifications-tag specifications-tag-' + boxSizeTypeIndex" v-for="(boxSizeType, boxSizeTypeIndex) in this.boxSizeTypes[box[activeBox].size]" v-bind:key="boxSizeTypeIndex">{{ tagsText[boxSizeTypeIndex] }} <strong v-html="boxSizeType + ((boxSizeTypeIndex in tagsAfterText) ? ' ' + tagsAfterText[boxSizeTypeIndex] : '')  "></strong></span>
          </div>

          <h3 class="gp-box-booking-title">Зв'язатися з менеджером</h3>

          <div class="gp-box-form-body" v-if="formSuccesed">
            Дякуємо, ми зв'яжемось з Вами найближчим часом!
          </div>
          <div class="gp-box-form-body" v-else>
            <form @submit.prevent="onSubmit">
              <!-- <div class="row mb-3">
                <label for="inputEmail3" class="col-sm-6 text-right col-form-label">Ім’я</label>
                <div class="col-sm-6">
                  <input type="text" v-model="clientContact" class="form-control"  />
                </div>
              </div> -->
              <div class="form-floating mb-3">
                <input type="text" class="form-control gp-input" v-model="clientName" id="clientName" placeholder="Ім'я">
                <label for="clientName">Ім'я</label>
              </div>

              <div class="form-floating mb-3">
                <input type="text" class="form-control gp-input" v-model="clientContact" id="clientContact" placeholder="Телефон або е-мейл">
                <label for="clientContact">Телефон або е-мейл</label>
              </div>

              <button type="submit" class="gent-button">Відправити заявку</button>
            </form>
          </div>

        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// import { Modal } from 'bootstrap'

export default {
  data() {
    return {
      // modal: null,
      // modalSuccesed: false,
      formSuccesed: false,
      clientName: '',
      clientContact: '',
      activeBox: false,
      transitionPosition: 'slide-fade',
      bookingInfoClass: 'gp-box-booking-info',
      tagsText: {
        line: "Лінія",
        area: "Площа",
        width: "Ширина",
        length: "Довжина",
        height: "Висота",
        gateWidth: "Ширина в'їзду",
      },
      tagsAfterText: {
        area: "m2",
        width: "m",
        length: "m",
        height: "m",
        gateWidth: "m",
      },
      boxSizeTypes: {
        "1": {
          line: 1,
          area: "79",
          width: "8.25",
          length: "9.6",
          height: "4",
          gateWidth: "3.4"
        },
        "2": {
          line: 1,
          area: "161",
          width: "8.25",
          length: "19.58",
          height: "4",
          gateWidth: "3.4"
        },
        "3": {
          line: 2,
          area: "46",
          width: "5.8",
          length: "8.04",
          height: "4",
          gateWidth: "2.5"
        },
        "4": {
          line: 2,
          area: "79",
          width: "8.25",
          length: "9.6",
          height: "4",
          gateWidth: "3.4"
        },
        "5": {
          line: 2,
          area: "88",
          width: "7.54",
          length: "11.72",
          height: "4",
          gateWidth: "3.4"
        },
        "6": {
          line: 2,
          area: "143",
          width: "17.4",
          length: "8.25",
          height: "4",
          gateWidth: "2.5"
        },
        "7": {
          line: 2,
          area: "147",
          width: "17.25",
          length: "8.25",
          height: "4",
          gateWidth: "2.5"
        }
      },
      box: {
        "A01": { status: "reserved", size: "1", price: "800", fullPrice: "63200", rentPriceM2: "12", fullRentPriceM2: "11376", tempClass: '' },
        "A02": { status: "free", size: "1", price: "800", fullPrice: "63200", rentPriceM2: "12", fullRentPriceM2: "11376", tempClass: '' },
        "A03": { status: "free", size: "1", price: "800", fullPrice: "63200", rentPriceM2: "12", fullRentPriceM2: "11376", tempClass: '' },
        "A04": { status: "free", size: "1", price: "800", fullPrice: "63200", rentPriceM2: "12", fullRentPriceM2: "11376", tempClass: '' },
        "A05": { status: "free", size: "1", price: "800", fullPrice: "63200", rentPriceM2: "12", fullRentPriceM2: "11376", tempClass: '' },
        "A06": { status: "free", size: "1", price: "800", fullPrice: "63200", rentPriceM2: "12", fullRentPriceM2: "11376", tempClass: '' },
        "A07": { status: "free", size: "1", price: "800", fullPrice: "63200", rentPriceM2: "12", fullRentPriceM2: "11376", tempClass: '' },
        "B01": { status: "free", size: "1", price: "800", fullPrice: "63200", rentPriceM2: "12", fullRentPriceM2: "11376", tempClass: '' },
        "B02": { status: "free", size: "1", price: "800", fullPrice: "63200", rentPriceM2: "12", fullRentPriceM2: "11376", tempClass: '' },
        "B03": { status: "free", size: "1", price: "800", fullPrice: "63200", rentPriceM2: "12", fullRentPriceM2: "11376", tempClass: '' },
        "B04": { status: "free", size: "1", price: "800", fullPrice: "63200", rentPriceM2: "12", fullRentPriceM2: "11376", tempClass: '' },
        "B05": { status: "free", size: "1", price: "800", fullPrice: "63200", rentPriceM2: "12", fullRentPriceM2: "11376", tempClass: '' },
        "B06": { status: "free", size: "1", price: "800", fullPrice: "63200", rentPriceM2: "12", fullRentPriceM2: "11376", tempClass: '' },
        "B07": { status: "free", size: "2", price: "800", fullPrice: "128800", rentPriceM2: "12", fullRentPriceM2: "23184", tempClass: '' },
        "A08": { status: "free", size: "4", price: "650", fullPrice: "51350", rentPriceM2: "8", fullRentPriceM2: "7584", tempClass: '' },
        "A09": { status: "free", size: "4", price: "650", fullPrice: "51350", rentPriceM2: "8", fullRentPriceM2: "7584", tempClass: '' },
        "A10": { status: "free", size: "4", price: "650", fullPrice: "51350", rentPriceM2: "8", fullRentPriceM2: "7584", tempClass: '' },
        "A11": { status: "free", size: "4", price: "650", fullPrice: "51350", rentPriceM2: "8", fullRentPriceM2: "7584", tempClass: '' },
        "A12": { status: "free", size: "4", price: "650", fullPrice: "51350", rentPriceM2: "8", fullRentPriceM2: "7584", tempClass: '' },
        "A13": { status: "free", size: "4", price: "650", fullPrice: "51350", rentPriceM2: "8", fullRentPriceM2: "7584", tempClass: '' },
        "A14": { status: "free", size: "4", price: "650", fullPrice: "51350", rentPriceM2: "8", fullRentPriceM2: "7584", tempClass: '' },
        "B08": { status: "free", size: "4", price: "650", fullPrice: "51350", rentPriceM2: "8", fullRentPriceM2: "7584", tempClass: '' },
        "B09": { status: "free", size: "4", price: "650", fullPrice: "51350", rentPriceM2: "8", fullRentPriceM2: "7584", tempClass: '' },
        "B10": { status: "free", size: "4", price: "650", fullPrice: "51350", rentPriceM2: "8", fullRentPriceM2: "7584", tempClass: '' },
        "B11": { status: "free", size: "4", price: "650", fullPrice: "51350", rentPriceM2: "8", fullRentPriceM2: "7584", tempClass: '' },
        "B12": { status: "free", size: "4", price: "650", fullPrice: "51350", rentPriceM2: "8", fullRentPriceM2: "7584", tempClass: '' },
        "B13": { status: "free", size: "4", price: "650", fullPrice: "51350", rentPriceM2: "8", fullRentPriceM2: "7584", tempClass: '' },
        "C01": { status: "free", size: "5", price: "650", fullPrice: "57200", rentPriceM2: "8", fullRentPriceM2: "8448", tempClass: '' },
        "C02": { status: "free", size: "5", price: "650", fullPrice: "57200", rentPriceM2: "8", fullRentPriceM2: "8448", tempClass: '' },
        "C03": { status: "free", size: "5", price: "650", fullPrice: "57200", rentPriceM2: "8", fullRentPriceM2: "8448", tempClass: '' },
        "C04": { status: "free", size: "5", price: "650", fullPrice: "57200", rentPriceM2: "8", fullRentPriceM2: "8448", tempClass: '' },
        "C05": { status: "free", size: "3", price: "650", fullPrice: "29900", rentPriceM2: "8", fullRentPriceM2: "4416", tempClass: '' },
        "C06": { status: "free", size: "3", price: "650", fullPrice: "29900", rentPriceM2: "8", fullRentPriceM2: "4416", tempClass: '' },
        "C07": { status: "free", size: "3", price: "650", fullPrice: "29900", rentPriceM2: "8", fullRentPriceM2: "4416", tempClass: '' },
        "C08": { status: "free", size: "3", price: "650", fullPrice: "29900", rentPriceM2: "8", fullRentPriceM2: "4416", tempClass: '' },
        "C09": { status: "free", size: "3", price: "650", fullPrice: "29900", rentPriceM2: "8", fullRentPriceM2: "4416", tempClass: '' },
        "D01": { status: "free", size: "3", price: "650", fullPrice: "29900", rentPriceM2: "8", fullRentPriceM2: "4416", tempClass: '' },
        "D02": { status: "free", size: "3", price: "650", fullPrice: "29900", rentPriceM2: "8", fullRentPriceM2: "4416", tempClass: '' },
        "D03": { status: "free", size: "3", price: "650", fullPrice: "29900", rentPriceM2: "8", fullRentPriceM2: "4416", tempClass: '' },
        "D04": { status: "free", size: "3", price: "650", fullPrice: "29900", rentPriceM2: "8", fullRentPriceM2: "4416", tempClass: '' },
        "D05": { status: "free", size: "3", price: "650", fullPrice: "29900", rentPriceM2: "8", fullRentPriceM2: "4416", tempClass: '' },
        "D06": { status: "free", size: "3", price: "650", fullPrice: "29900", rentPriceM2: "8", fullRentPriceM2: "4416", tempClass: '' },
        "D07": { status: "free", size: "3", price: "650", fullPrice: "29900", rentPriceM2: "8", fullRentPriceM2: "4416", tempClass: '' },
        "D08": { status: "free", size: "3", price: "650", fullPrice: "29900", rentPriceM2: "8", fullRentPriceM2: "4416", tempClass: '' },
        "D09": { status: "free", size: "3", price: "650", fullPrice: "29900", rentPriceM2: "8", fullRentPriceM2: "4416", tempClass: '' },
        "D10": { status: "free", size: "3", price: "650", fullPrice: "29900", rentPriceM2: "8", fullRentPriceM2: "4416", tempClass: '' },
        "E01": { status: "free", size: "3", price: "450", fullPrice: "20700", rentPriceM2: "8", fullRentPriceM2: "4416", tempClass: '' },
        "E02": { status: "free", size: "3", price: "450", fullPrice: "20700", rentPriceM2: "8", fullRentPriceM2: "4416", tempClass: '' },
        "E03": { status: "free", size: "3", price: "450", fullPrice: "20700", rentPriceM2: "8", fullRentPriceM2: "4416", tempClass: '' },
        "E04": { status: "free", size: "3", price: "450", fullPrice: "20700", rentPriceM2: "8", fullRentPriceM2: "4416", tempClass: '' },
        "E05": { status: "free", size: "3", price: "450", fullPrice: "20700", rentPriceM2: "8", fullRentPriceM2: "4416", tempClass: '' },
        "E06": { status: "free", size: "3", price: "450", fullPrice: "20700", rentPriceM2: "8", fullRentPriceM2: "4416", tempClass: '' },
        "E07": { status: "free", size: "3", price: "450", fullPrice: "20700", rentPriceM2: "8", fullRentPriceM2: "4416", tempClass: '' },
        "E08": { status: "free", size: "3", price: "450", fullPrice: "20700", rentPriceM2: "8", fullRentPriceM2: "4416", tempClass: '' },
        "E09": { status: "free", size: "3", price: "450", fullPrice: "20700", rentPriceM2: "8", fullRentPriceM2: "4416", tempClass: '' },
        "E10": { status: "free", size: "3", price: "450", fullPrice: "20700", rentPriceM2: "8", fullRentPriceM2: "4416", tempClass: '' },
        "F01": { status: "free", size: "3", price: "650", fullPrice: "29900", rentPriceM2: "8", fullRentPriceM2: "4416", tempClass: '' },
        "F02": { status: "free", size: "3", price: "650", fullPrice: "29900", rentPriceM2: "8", fullRentPriceM2: "4416", tempClass: '' },
        "F03": { status: "free", size: "3", price: "650", fullPrice: "29900", rentPriceM2: "8", fullRentPriceM2: "4416", tempClass: '' },
        "F04": { status: "free", size: "3", price: "650", fullPrice: "29900", rentPriceM2: "8", fullRentPriceM2: "4416", tempClass: '' },
        "F05": { status: "free", size: "3", price: "650", fullPrice: "29900", rentPriceM2: "8", fullRentPriceM2: "4416", tempClass: '' },
        "F06": { status: "free", size: "3", price: "650", fullPrice: "29900", rentPriceM2: "8", fullRentPriceM2: "4416", tempClass: '' },
        "F07": { status: "free", size: "3", price: "650", fullPrice: "29900", rentPriceM2: "8", fullRentPriceM2: "4416", tempClass: '' },
        "F08": { status: "free", size: "3", price: "650", fullPrice: "29900", rentPriceM2: "8", fullRentPriceM2: "4416", tempClass: '' },
        "F09": { status: "free", size: "3", price: "650", fullPrice: "29900", rentPriceM2: "8", fullRentPriceM2: "4416", tempClass: '' },
        "F10": { status: "free", size: "3", price: "650", fullPrice: "29900", rentPriceM2: "8", fullRentPriceM2: "4416", tempClass: '' },
        "G01": { status: "free", size: "6", price: "750", fullPrice: "110250", rentPriceM2: "8", fullRentPriceM2: "14112", tempClass: '' },
        "G02": { status: "free", size: "7", price: "750", fullPrice: "107250", rentPriceM2: "8", fullRentPriceM2: "13728", tempClass: '' }
      }
    }
  },
  methods: {
    boxInfo(e) {
      // return this.$router.push(`/plan/floor/${floor}`);
      let currentId = e.currentTarget.getAttribute('id');

      if(this.activeBox) {
        this.activeBox = false;
        setTimeout(() => {
          this.activeBox = currentId;
          this.setActivePosition();
        }, 300)
      } else {
        this.activeBox = currentId;
        this.setActivePosition();
      }
    },
    setActivePosition() {
      console.log(this.activeBox.charAt(0));
      if(this.activeBox.charAt(0) == 'A' || this.activeBox.charAt(0) == 'C') {
        this.transitionPosition = 'slide-fade-right';
        this.bookingInfoClass = 'gp-box-booking-info gp-box-booking-info-right';
      } else {
        this.transitionPosition = 'slide-fade-left';
        this.bookingInfoClass = 'gp-box-booking-info gp-box-booking-info-left';
      }
    },
    showSameBoxes(boxesString) {
      boxesString.split(',').forEach(function(item) {
        this.box[item].tempClass = 'gp-box-plan-flash';
      }, this)
      setTimeout(() => {
        boxesString.split(',').forEach(function(item) {
          this.box[item].tempClass = '';
        }, this)
      }, 7000)
    },
    pathClass(id) {
      let defaultClass = 'gp-box-plan-selector';
      if(this.box[id].tempClass.length > 0) defaultClass += ' ' + this.box[id].tempClass;
      if(this.box[id].status == 'reserved') defaultClass += ' gp-box-plan-reserved';
      return defaultClass;
    },
    closeBoxInfo() {
      this.activeBox = false;
    }
  },
  computed: {
    title() {
      return 'Блок ' + this.activeBox.charAt(0) + ', Бокс ' + parseInt(this.activeBox.slice(1));
    },
    // sameSizeItems() {
    //   return this.box.forEach(element => {
    //     console.log(element);
    //     if (element.size == this.boxSizeTypes[this.box[this.activeBox].size]) {
    //       console.log(element);
    //       return element;
    //     }
    //   });
    // }
    sameSizeItems() {
      var same = Array();
      for (var key in this.box) {
        if(this.box[key].size == this.box[this.activeBox].size) {
          same.push(key);
        }
          // alert( "Ключ: " + key + " значение: " + menu[key] );
      }
      return { count: same.length, elements: same.toString()};
      // return this.box.filter(element => {
      //   return element.size == this.box[this.activeBox].size
      // }).id;
    }
  },
}
</script>

<style lang="scss">
.gp-box-booking {
  height: 100vh;
  width: 100vw;
}
.gp-box-plan-selector {
  fill: #C4C4C4;
  fill-opacity: 0;
  stroke: #FFFFFF;
  transition: fill .3s, fill-opacity .25s;
  &.gp-box-plan-flash {
    fill-opacity: 0.3;
    fill: #64ff81;
    transition: fill .7s, fill-opacity .7s;
  }
  &.gp-box-plan-reserved  {
    cursor: not-allowed;
    fill-opacity: 0.4;
    fill: #C4C4C4;
  }
  &:hover, &.gp-box-plan-flash:hover {
    fill: #0094FF;
    fill-opacity: 0.35;
    cursor: pointer;
    &.gp-box-plan-reserved  {
      cursor: not-allowed;
      fill-opacity: 0.8;
      fill: #C4C4C4;
    }
  }
}
.gp-box-booking-info {
  position: absolute;
  width: 30vw;
  top: 0;
  height: 100vh;
  background: #fff;
  box-shadow: -5px 0px 35px rgba(0, 0, 0, 0.3);

  display: flex;
  justify-content: center;
  align-items: center;
  .gp-box-booking-container {
    max-width: 500px;
  }
  &.gp-box-booking-info-right {
    left: auto;
    right: 0;
    .gp-box-booking-container {
      margin: 0 82px 0 48px;
    }
  }
  &.gp-box-booking-info-left {
    left: 0;
    right: auto;
    .gp-box-booking-container {
      margin: 24px;
      margin: 0 24px 0 48;
    }
  }
}
.gp-box-booking-suptitle {
  margin-top: 2em;
  margin-bottom: 2em;
  span {
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    &:hover {
      color: #CE603C;
    }
  }
}
.gp-box-specifications {
  margin-bottom: 3em;
  &:after {
    display: block;
    content: "";
    clear: both;
  }
  .specifications-tag-line {
    display: none;
  }
}

.gp-box-form-body {
  max-width: 460px;
  button {
    margin: 0 auto;
    display: block;
  }
}
.gp-input {
  border: 0px;
  border-bottom: 2px solid rgba(128, 128, 128, 0.35);
  border-radius: 0;
  &:focus {
    border-bottom: 2px solid #333;
    box-shadow: none;
  }
}

/* Анимации появления и исчезновения могут иметь */
/* различные продолжительности и динамику.       */
.slide-fade-right-enter-active, .slide-fade-left-enter-active {
  transition: all .3s ease;
}
.slide-fade-right-leave-active, .slide-fade-left-leave-active {
  transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-right-enter, .slide-fade-right-leave-to
/* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateX(10vw);
  opacity: 0;
}
.slide-fade-left-enter, .slide-fade-left-leave-to
/* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateX(-40vw);
  opacity: 0;
}
</style>
