<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class='col-md-6'>
          <div class="gp-box-contact-block">
            <div class="gp-box-contact-text">
              <h3>Контакти</h3>

              <p class="contact-legend">Офіс:</p>
              <p class="contact-value">м. Тернопіль, вул. За Рудкою 33, офіс 27</p>

              <p class="contact-legend">Телефон для консультацій:</p>
              <p class="contact-value">+38 /098/ 099-07-07</p>

              <p class="contact-legend">Email:</p>
              <p class="contact-value">office@gentleman-park.com.ua</p>
            </div>
          </div>
        </div>
        <div class='col-md-6'>
          <GmapMap
            :center="{lat: 49.5338635, lng: 25.6205648}"
            :zoom="16"
            map-type-id="terrain"
            class="gp-box-map"
          >
            <GmapMarker ref="myMarker"
              :position="google && new google.maps.LatLng(49.5338635, 25.6205648)" />
            <!-- <GmapMarker
              :key="index"
              v-for="(m, index) in markers"
              :position="m.position"
              :clickable="true"
              :draggable="true"
              @click="center=m.position"
            /> -->
          </GmapMap>

        </div>
      </div>
    </div>
</div>
</template>

<script>
import {gmapApi} from 'vue2-google-maps'

export default {
  name: 'LocationsSlide',
  computed: {
    google: gmapApi
  }
  // beforeCreate: function() {
  //     document.body.className = 'fp-viewing-home';
  // }
}
</script>

<style lang="scss">
.gp-box-map {
  width: 100%;
  height: 300px;
  margin-top: 2em;
}
@media only screen and (min-width: 768px) {

  .gp-box-map {
    width: calc(100% - 70px);
    height: calc(100vh - 164px);
    margin-top: 0;
  }
  .gp-box-contact-block {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
  }
  .gp-box-contact-text {
    width: 600px;
  }
}
</style>
